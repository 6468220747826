import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PartnersFr = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Partenaires"
      description="Les acteurs touristiques du Jura : campings, chambres d'hôtes, gites, hôtels, ..."
    />
    <h3>Partenaires</h3>
    <p>
      Le tourisme s'envisage dans la mesure du possible dans une vision
      d'ensemble et globale, en tout cas c'est comme ça que je le vois. Il est
      important que des liens professionnels et de confiance se forment entre
      acteurs touristiques d'un même territoire.
    </p>
    <p>
      Cette page est dédiée aux personnes et structures partageant cette vision.
    </p>
    <p>
      Merci à celles et à ceux qui me soutiennent dans mon aventure
      professionnelle et qui me soutiendront.
    </p>
    <p>Bon séjour dans notre belle région !</p>
    <p>Ludovic Guyot</p>
    <p>Lacuzon loisirs</p>
    <h4 className="mt-5" style={{color: "#000"}}>Les hébergements :</h4>
    <ul className="li-rando">
      <li>
        {" "}
        <p>
          <strong style={{color: "#000", fontSize: 18}}>Campings</strong>
        </p>
        <p>
          camping *** la Saline-les bords de Loue à Arc-et-Senans (25) :{" "}
          <a href="https://www.camping-des-bords-de-loue.fr/">
            www.camping-des-bords-de-loue.fr
          </a>
        </p>
        <p>
          camping les promenades à Quingey (25) :{" "}
          <a href="https://www.quingeycamping.fr/">www.quingeycamping.fr</a>
        </p>
        <p>
          camping la petite montagne -Predulac à Moirans-en Montagne (39) :{" "}
          <a href="https://www.campinglapetitemontagne.com/">
            www.campinglapetitemontagne.com
          </a>
        </p>
      </li>
      <li>
        <p>
          <strong style={{color: "#000", fontSize: 18}}>Chambres d'hôtes</strong>
        </p>
        <p>
          Au champ du bois à Sampans (39) :{" "}
          <a href="https://www.champsdubois.fr/">www.champsdubois.fr</a>
        </p>
        <p>
          Domaine de Bersaillin (39):{" "}
          <a href="https://www.domainedebersaillin.fr/">
            www.domainedebersaillin.fr
          </a>
        </p>
      </li>

      <li>
        <p>
          <strong style={{color: "#000", fontSize: 18}}>Gites</strong>
        </p>

        <p>
          les Bouc'Tins à Molain (39) :{" "}
          <a href="http://lesbouctins.unblog.fr/page/2/">
            lesbouctins.unblog.fr
          </a>
        </p>

        <p>
          les 4 saisons à Marigny (39):
          <a href="https://www.lac-chalain.com/gite-les-quatre-saisons/">
            www.lac-chalain.com/gite-les-quatre-saisons
          </a>
        </p>
      </li>
      <li>
        <p>
          <strong style={{color: "#000", fontSize: 18}}>Hôtels</strong>
        </p>

        <p>
          Hôtel-Restaurant le Castel d'amandre aux Planches-près d'Arbois
          : <a href="https://www.casteldamandre.com/fr/">www.casteldamandre.com</a>
        </p>
      </li>
    </ul>
  </Layout>
)

export default PartnersFr
